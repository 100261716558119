import coldImg from '../../image/coolWhite.png';
import hotImg from '../../image/hotWhite.png';

(function() {
    angular.module('EntrakV5').controller('logController', logController);

    function logController($scope, $rootScope, $window, $timeout, Service, Api, KEY, APIKEY) {
        console.log('logController');

        var caller = Api.createApiCaller();
        var nameSorter = Service.getSorterIgnoreCase();
        $rootScope.title = Service.translate("log.title");
        $scope.selectedData = [];
        // $scope.smartlightPower = 35 / 60;// Watt/60mins  //no customer use it currently
        var imgCold = document.createElement('img');
        var imgHot = document.createElement('img');
        imgCold.src = coldImg;
        imgHot.src = hotImg;
        $scope.downloadBtnStr = '';
        var downloadStartDate = null;
        var downloadEndDate = null;

        $scope.selectedNodeType = "workstations";
        $scope.selectedZoneId = null;
        $scope.zoneMap = null;

        var unknownUser = Service.translate("label.unknownUser");
        $scope.userMap = {};

        function isActionOn(action){//return true if the action is only available when checked in or the action itself is checkin
            return action !== APIKEY.action.checkout && action !== APIKEY.action.allOff && action !== APIKEY.action.unknown;
        }
        function isAirconAction(action){
            return action === APIKEY.action.normal || action === APIKEY.action.warmer || action === APIKEY.action.cooler;
        }

        function getWsList() {
            return $scope.selectedZoneId && $scope.zoneMap ? $scope.zoneMap[$scope.selectedZoneId].workstations : [];
        }
        function getRmList() {
            return $scope.selectedZoneId && $scope.zoneMap ? $scope.zoneMap[$scope.selectedZoneId].rooms : [];
        }

    /* load data */
        $rootScope.getTenantId().then(function(tenantId){
            $rootScope.loadingPage = 1;
            caller.call([Api.getProfiles(tenantId, 0), Api.getFloorList(tenantId, true)]).then(function(res){
                $scope.userMap = Service.arrayToMap(res[0]);
                res[1].zones = $rootScope.getFilteredZoneList(res[1].zones);
                res[1].zones.sort(nameSorter);
                $scope.floorDropdown.setDataSource(new kendo.data.DataSource({
                    data: res[1].zones
                }));
                $scope.selectedZoneId = res[1].zones[0].id;
                res[1].zones.forEach(function(z){
                  z.workstations.forEach(ws => {
                    ws.type = APIKEY.nodeTypeInv.WORKSTATION;
                    Service.updateWsName(ws, $scope.userMap);
                  });
                  z.rooms.forEach(rm => {
                    rm.type = APIKEY.nodeTypeInv.ROOM;
                  });
                  z.workstations.sort(nameSorter);
                  z.rooms.sort(nameSorter);
                });
                $scope.zoneMap = Service.arrayToMap(res[1].zones);
                $scope.getData();
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        });

        $scope.curLoadingNodeIndex = 0;
        $scope.getData = function(){
            if (!$scope.selectedZoneId)
                return;
            var now = Math.floor(Date.now() / 1000);
            $scope.logStartTime = new Date($scope.calendarDate);
            $scope.logStartTime.setHours(0, 0, 0, 0);
            downloadStartDate = kendo.date.dayOfWeek($scope.logStartTime, 0, -1);
            downloadEndDate = new Date(downloadStartDate);
            downloadEndDate.setDate(downloadEndDate.getDate() + 6);// +6 for display
            $scope.downloadBtnStr = Service.translate("log.downloadCsv", {
                from: Service.dateFmt(downloadStartDate, "short"),
                to: Service.dateFmt(downloadEndDate, "short")
            });
            downloadEndDate.setDate(downloadEndDate.getDate() + 1);// +1(+7) for calculation
            $scope.logStartTime = Service.getUnixTimestamp($scope.logStartTime);
            $scope.logEndTime = Service.addMinute($scope.logStartTime, 60 * 24);

            if ($scope.logEndTime > now){
                $scope.cutoffCellX = getCellXByTime(now);
                if ($scope.cutoffCellX < getCellXByTime($scope.logEndTime))//show data up to now, no 15mins delay
                    $scope.cutoffCellX++;
            } else {
                $scope.cutoffCellX = null;
            }

            //clear data and set loading
            var nodes = [];
            if ($scope.selectedNodeType === "all"){
              nodes = nodes.concat(getWsList()).concat(getRmList());
            } else if ($scope.selectedNodeType === "workstations"){
              nodes = nodes.concat(getWsList());
            } else {
              nodes = nodes.concat(getRmList());
            }
            nodes.forEach(function(n){
              n.loading = true;
              n.records = [];
            });

            //start call api and update ui
            $scope.curLoadingNodeIndex = 0;
            $scope.selectedData = nodes;
            $scope.redrawHeatmap();  //clear heatmap ui
            $scope.redrawChart(true);  //clear chart ui
            $scope._getData($scope.logStartTime, $scope.logEndTime, $scope.selectedZoneId, $scope.selectedNodeType);
        }

        $scope._getData = function(sTime, eTime, zId, nType) {
          if ($scope.selectedData.length > $scope.curLoadingNodeIndex) {
            $rootScope.loadingPage++;
            var previousStartTime = sTime - 24 * 3600;
            var wsArr = [];
            var rmArr = [];
            var count = Math.min($scope.curLoadingNodeIndex + 10, $scope.selectedData.length);
            for (; $scope.curLoadingNodeIndex < count; $scope.curLoadingNodeIndex++){
              var n = $scope.selectedData[$scope.curLoadingNodeIndex];
              if (n.type === APIKEY.nodeTypeInv.WORKSTATION){
                wsArr.push(n);
              } else {
                rmArr.push(n);
              }
            }
            caller.call(Api.getLogPageDataByNodes(previousStartTime, eTime, wsArr.map(n => n.id), rmArr.map(n => n.id))).then(function(res){
              //user change input param before finish loading, dont need to render, and stop getting other node's data
              if (sTime != $scope.logStartTime || zId != $scope.selectedZoneId || nType != $scope.selectedNodeType){
                $rootScope.loadingPage--;
                return;
              }

              //call api again to get data for another node
              $scope._getData(sTime, eTime, zId, nType);

              //process log data
              var nodeArr = wsArr.concat(rmArr);
              var nodeMap = Service.arrayToMap(nodeArr, "id");
              var unknownMap = {};
              res.nodeActivitiesLog.sort((a, b) => a.timestamp - b.timestamp);
              res.nodeActivitiesLog.forEach(function(activity){
                //each node will have a daily dummy record, but only the yesterday record is useful
                if (activity.triggerBy === APIKEY.triggerBy.dummyRecord && activity.timestamp >= sTime)
                  return;
                nodeMap[activity.nodeId].records.push({
                  timestamp: activity.timestamp,
                  action: activity.action,
                  remark: activity?.remark||'-',
                  triggerId: activity.triggerBy === APIKEY.triggerBy.user ? activity.triggerId : null,
                  triggerType: Service.translate("log.triggerType." + activity.triggerBy)
                });
                if (activity.action === APIKEY.action.unknown)
                  unknownMap[activity.nodeId] = true;
              });

              //process previous status
              nodeArr.forEach(n => {
                var r = n.records;
                var prevStatus = null;
                for (var i=0; i<r.length; i++) {
                  if (r[i].timestamp >= sTime){//should have least one record before sTime but not guaranteed
                    prevStatus = r;
                    n.records = prevStatus.splice(i);
                    r = n.records;
                    if (i == 0) {//only have selected range record
                      prevStatus = {
                        timestamp: sTime,
                        action: APIKEY.action.checkout
                      }
                      console.error("unexpected error, no previous record found, assume previous status is offline");
                    } else {//have previous and selected range record
                      prevStatus = {
                        action: (isActionOn(prevStatus[prevStatus.length-1].action) ? APIKEY.action.checkin : APIKEY.action.checkout),
                        timestamp: sTime
                      }
                    }
                    break;
                  }
                }
                if (prevStatus === null) {
                  if (r.length){//only have previous record
                    prevStatus = {
                      action: (isActionOn(r[r.length-1].action) ? APIKEY.action.checkin : APIKEY.action.checkout),
                      timestamp: sTime
                    }
                  } else {//no record
                    prevStatus = {
                      action: APIKEY.action.checkout,
                      timestamp: sTime
                    }
                    console.error("unexpected error, no previous record found, assume previous status is offline");
                  }
                  n.records = [prevStatus];
                } else {
                  n.records.unshift(prevStatus);
                }
              });

              //update ui
              var ctx = canvas.get(0).getContext("2d");
              nodeArr.forEach(n => {
                n.loading = false;
                $scope.redrawHeatmapRow(n, ctx);
              });
              $scope.redrawChart();
                
              //show error
              for (var id in unknownMap) {
                if (unknownMap.hasOwnProperty(id)) {
                  console.error(nodeMap[id].name + " contains UNKNOWN action");
                  $rootScope.showError(Service.translate("log.containUnknown", { name: nodeMap[id].name }));
                }
              }
              $rootScope.loadingPage--;
            }, function(err){
              if (err === KEY.ignore)
                return;
              $rootScope.generalErrorHandler();
            });
          }
        }

        $scope.areaDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("log.allGroups"),
                value: "all",
            }, {
                text: Service.translate("log.workstations"),
                value: "workstations",
            }, {
                text: Service.translate("log.rooms"),
                value: "rooms",
            }],
            dataTextField: "text",
            dataValueField: "value",
            change: function(e) {
              $timeout($scope.getData);
            }
        }

        $scope.floorDropdownOpt = {
            autoWidth: true,
            dataTextField: "name",
            dataValueField: "id",
            change: function(e) {
              $timeout($scope.getData);
            }
        }

        $scope.calendarDate = new Date();
        $scope.calendarOpt = {
            depth: "month",
            start: "month",
            footer: false,
            format: "D",
            max: new Date(),
            change: function(e){
              $scope.datePopup.close();
              $timeout($scope.getData);
            }
        }
        $scope.datePopupOpt = {
            anchor: $("#log .dateDropdownBtn"),
            origin: "bottom left",
            position: "top left",
        }
    /* load data */

    /* download csv */
        $scope.loadingCsv = false;
        $scope.nodeAircons = null;
        $scope.getCsv = function(){
            $scope.loadingCsv = true;
            var fileName = "floor-activity-" + downloadStartDate.toLocaleString("en-GB", { day: "2-digit", month: "short" }) + '-' + downloadEndDate.toLocaleString("en-GB", { day: "2-digit", month: "short" }) + ".csv";
            var needGetDevice = !$scope.nodeAircons;
            var nodeMap = Service.arrayToMap(getWsList(), "id");
            Service.arrayToMap(getRmList(), "id", nodeMap);
            var apiPromise = needGetDevice ? caller.call([
                Api.getLogPageCsvData(Service.getUnixTimestamp(downloadStartDate), Service.getUnixTimestamp(downloadEndDate), $scope.selectedZoneId),
                Api.getNodeAircons()
            ], true) : caller.call(Api.getLogPageCsvData(Service.getUnixTimestamp(downloadStartDate), Service.getUnixTimestamp(downloadEndDate), $scope.selectedZoneId), true);
            apiPromise.then(function(res){
                if (needGetDevice) {
                    $scope.nodeAircons = Service.arrayToMap(res[1], "nodeId");
                    res = res[0];
                }
                var tmp, name, str = 'Date,Name,Aircons,Action,Requester,TriggerBy\n';
                var wsSuffix = Service.translate("label.workstationName", {fName: "", lName: ""}).trim();
                for (var i=0; i<res.nodeActivitiesLog.length; i++){
                    tmp = res.nodeActivitiesLog[i];
                    if (tmp.triggerBy === APIKEY.triggerBy.dummyRecord)
                        continue;
                    var n = nodeMap[tmp.nodeId];
                    if (!n) {
                        console.error("nodeId not found in ws/rm map (csv)", tmp.nodeId);
                        $rootScope.showError("Missing group data, please contact admin.");
                        continue;
                    } else if (n.type === APIKEY.nodeTypeInv.WORKSTATION) {
                        if (n.owners.length){
                            var user = $scope.userMap[n.owners[0].id];
                            name = (user ? user.email.split("@")[0] : unknownUser) + wsSuffix;
                        } else {
                            name = '"' + n.name.replace(/"/g, '\'') + '"';
                        }
                    } else if (n.type === APIKEY.nodeTypeInv.ROOM) {
                        name = '"' +  n.name.replace(/"/g, '\'') + '"';
                    }

                    if (!n.airconSerials) {
                        n.airconSerials = $scope.nodeAircons[tmp.nodeId];
                        if (n.airconSerials) {
                            n.airconSerials = n.airconSerials.serials;
                        } else {
                            n.airconSerials = [];
                        }
                    }
                    var triggerEmail = $scope.userMap[tmp.triggerId];
                    triggerEmail = triggerEmail ? triggerEmail.email : unknownUser;
                    if (n.airconSerials.length == 0){
                        str += Service.dateFmt(tmp.timestamp, "s") + ',' + name + ',,' + tmp.action + ',' + (tmp.triggerBy === APIKEY.triggerBy.user ? triggerEmail : '') + ',' + tmp.triggerBy + '\n';
                    } else {
                        for (var j=0; j<n.airconSerials.length; j++){
                            str += Service.dateFmt(tmp.timestamp, "s") + ',' + name + ',' + n.airconSerials[j] + ',' + tmp.action + ',' + (tmp.triggerBy === APIKEY.triggerBy.user ? triggerEmail : '') + ',' + tmp.triggerBy + '\n';
                        }
                    }
                }
                Service.downloadFile(fileName.replace(/ /g, ''), str);
                $scope.loadingCsv = false;
            }, function(err){
                $rootScope.generalErrorHandler(true);
                $scope.loadingCsv = false;
            });
        }
    /* download csv */

    /* grid */
        var canvasCol = $("#heatmapContainer");
        var canvas = $("#heatmapContainer #heatmapCanvas");

        var tooltipDummy = $("#tooltipDummy");
        var tooltipDummyElm = $("#tooltipDummy").get(0);
        var tooltipHover = $("#tooltipHover");
        var tooltipHoverElm = tooltipHover.get(0);
        var altBg = "#ffffff";
        var lightOffColor = "#e0e0e0";
        // var chartColColor = "#fafafa";
        var lightOffRGB = [224, 224, 224];
        var lightOnColor = "#0e3e4c";//319cbd
        var lightOnRGB = [14, 62, 76];//49, 156, 189
        var futureTimeWhite = "#fdfdfd";
        var coolerColor = "#319cbd";//lightOnColor
        var warmerColor = "#d0021b";

        var cellHeight = 30; //must be the same as @cellHeight in scss
        var cellWidth = 10; //dynamic calculate during redraw
        var cellPad = 10; //must be the same as @cellBtmPad in scss
        var rowHeight = cellHeight + cellPad;
        var maxWidth = 10; //dynamic calculate during redraw
        var bigColNum = 24;
        var colNum = bigColNum * 4; //15mins per cell

        var temperatureIconRadius = 3;
        var temperatureIconCenterYOffset = cellHeight / 4;
        var circleEndAngle = Math.PI * 2;

        //legend style
        $("#log .heatmapRow .colorBar").css({
            background: "linear-gradient(" + lightOffColor + ", " + lightOnColor + ")"
        });

        function getCellXByTime(uTimeOrDate){
            return Math.floor(Service.getMinuteDiff(uTimeOrDate, $scope.logStartTime) / 15);
        }

        //mins: duration of light ON time in minutes (15 max)
        function getColorByDuration(mins){
            if (mins > 15)
                mins = 15;

            var ratio = mins/15;
            var rgb = [        
                Math.round(lightOnRGB[0] * ratio + lightOffRGB[0] * (1 - ratio)),
                Math.round(lightOnRGB[1] * ratio + lightOffRGB[1] * (1 - ratio)),
                Math.round(lightOnRGB[2] * ratio + lightOffRGB[2] * (1 - ratio))
            ];
            return "rgb(" + rgb.toString() + ")";
        }

        $scope.prepareRedrawHeatmap = function(){
          $scope.cellPopup.close();

          var rowNum = $scope.selectedData.length;
          var maxHeight = rowNum * rowHeight;
          maxWidth = canvasCol.innerWidth();
          var bigColWidth = maxWidth / bigColNum;
          cellWidth = maxWidth / colNum;

          tooltipDummy.css({width: cellWidth+"px"});  //center tooltip to the cell
          tooltipHover.css({width: cellWidth+"px"});
          canvas.get(0).height = maxHeight;     //set height
          canvas.get(0).width = maxWidth;     //set height

          var ctx = canvas.get(0).getContext("2d");

          return { rowNum, maxHeight, bigColWidth, ctx };
        }
        //redraw whole canvas
        $scope.redrawHeatmap = function(){
          var { rowNum, bigColWidth, ctx } = $scope.prepareRedrawHeatmap();
          $scope.drawBg(ctx, bigColWidth, rowNum);
          for (var i=0; i<rowNum; i++){
            $scope.redrawHeatmapRow(i, ctx);
          }
        }
        $scope.redrawHeatmapRow = function(nodeOrIndex, ctx){
          if (typeof nodeOrIndex === "number") {
            var r = nodeOrIndex;
            var node = $scope.selectedData[r];
          } else {
            var r = $scope.selectedData.findIndex(n => n === nodeOrIndex);
            var node = nodeOrIndex;
          }

          if (node.loading)
            return;

          //draw cells
          node.totalOnTime = 0;//total on time for the whole day
          if (node.records.length > 1) {// has records, draw by blocks
            var isPrevOn = isActionOn(node.records[0].action);
            var prevCellX = 0;
            var prevTime = $scope.logStartTime;
            var accOnTime = 0;

            for (var t=1; t<=node.records.length; t++) {
              if (node.records.length == t){   //add a dummy record so that the loop can draw the last record
                var record = {
                  action: (isPrevOn ? APIKEY.action.checkout : APIKEY.action.checkin),
                  timestamp: $scope.logEndTime,
                }
              } else {    //normal record
                var record = node.records[t];    
              }

              //process each on/off changed record
              var isRecOn = isActionOn(record.action);
              if (isRecOn != isPrevOn) {
                var curTime = record.timestamp;
                var curCellX = getCellXByTime(curTime);
                if (curCellX == prevCellX) {    //status changed within a cell
                  if (!isRecOn) //save the accumulate ON time until next cell is reached
                    accOnTime += Service.getMinuteDiff(curTime, prevTime);
                } else {    //status changed across different cells
                  //1
                  //draw the prevCell (accOnTime)
                  if (isPrevOn){
                    //include the last record in prev cell for accumulate ON time
                    var minutePart = 15 - Service.modMinute(prevTime, 15);
                    if (minutePart == 0){
                      accOnTime = 15;
                    } else {
                      accOnTime += minutePart;    
                    }
                  }
                  $scope.drawBlock(ctx, getColorByDuration(accOnTime), prevCellX, r, 1);
                  node.totalOnTime += accOnTime;

                  //2
                  //draw the block(>= 1 cell) right after the prevCell
                  if (isPrevOn && curCellX+1 > prevCellX){
                    var cellCount = curCellX-prevCellX-1;
                    $scope.drawBlock(ctx, lightOnColor, prevCellX+1, r, cellCount);
                    node.totalOnTime += 15 * cellCount;
                  }

                  //3
                  //save the accumulative ON duration, will be used in the next iteration
                  if (isPrevOn){
                    accOnTime = Service.modMinute(curTime, 15);
                  } else {
                    accOnTime = 0;
                  }
                }

                prevTime = curTime;
                prevCellX = curCellX;
                isPrevOn = isRecOn;
              }
            }
          } else if (node.records.length == 1) {// only has previousState, draw whole row
            if (isActionOn(node.records[0].action)){
              var cellCount = $scope.cutoffCellX != null ? $scope.cutoffCellX : colNum;
              $scope.drawBlock(ctx, lightOnColor, 0, r, cellCount);
              node.totalOnTime = cellCount * 15;
            }
          } else {
            console.error('no record for this node', node);
          }
          //draw temperature icon
          node.records.forEach(function(rec){
            $scope.drawTempIcon(ctx, rec.action, getCellXByTime(rec.timestamp), r);
          });


          //draw today future time
          if ($scope.cutoffCellX != null && colNum > $scope.cutoffCellX){
            $scope.drawBlock(ctx, futureTimeWhite, $scope.cutoffCellX, r, colNum-$scope.cutoffCellX, lightOffColor);
          }
        }

        $scope.drawBg = function(ctx, bigColWidth, rowNum){
            //draw bg
            ctx.beginPath();
            ctx.fillStyle = lightOffColor;
            for (var i=0; i<rowNum; i++){
                ctx.rect(0, i*rowHeight, maxWidth, cellHeight);
            }
            ctx.fill();
            ctx.beginPath();
            ctx.fillStyle = altBg;
            for (var i=1; i<rowNum; i++){
                for (var j=0; j<bigColNum; j+=2){
                    ctx.rect(j*bigColWidth, i*rowHeight-cellPad, bigColWidth, cellPad);    
                }
            }
            ctx.fill();
        }
        //draw a rect, start from cellX to cellX+cellCount-1
        $scope.drawBlock = function(ctx, color, cellX, cellY, cellCount, borderColor){
            if (borderColor){//dont use strokeRect, very blur
                ctx.fillStyle = borderColor;
                ctx.fillRect(cellX*cellWidth, cellY*rowHeight, cellWidth*cellCount, cellHeight);
                ctx.fillStyle = color;
                ctx.fillRect(cellX*cellWidth+1, cellY*rowHeight+1, cellWidth*cellCount-2, cellHeight-2);
            } else {
                ctx.fillStyle = color;//use floor and ceil to fix canvas anti-aliasing problem
                ctx.fillRect(Math.floor(cellX*cellWidth), cellY*rowHeight, Math.ceil(cellWidth*cellCount), cellHeight);
            }
        }
        $scope.drawTempIcon = function(ctx, action, cellX, cellY){
            cellX += 0.5;
            if (action === APIKEY.action.warmer){
                ctx.beginPath();
                ctx.fillStyle = warmerColor;
                ctx.arc(cellX*cellWidth, cellY*rowHeight+temperatureIconCenterYOffset, temperatureIconRadius, 0, circleEndAngle);
                ctx.fill();
            } else if (action === APIKEY.action.cooler){
                ctx.beginPath();
                ctx.fillStyle = coolerColor;
                ctx.arc(cellX*cellWidth, cellY*rowHeight+cellHeight-temperatureIconCenterYOffset, temperatureIconRadius, 0, circleEndAngle);
                ctx.fill();
            }
        }

        var animationDuration = 100;
        $scope.cellPopupOpt = {
            anchor: $("#log #tooltipHover"),
            origin: "top center",
            position: "bottom center",
            animation: false,
            close: function(){
                $timeout(function(){
                    $scope.showTooltipDummy = false;
                });
            },
            open: function(){
                $timeout(function(){
                    $scope.showTooltipDummy = true;
                });
            }
        }

        $scope.actionByLbl = function(record){
            var triggerBy = record.triggerId ? $scope.userMap[record.triggerId] : null;
            if (record.triggerId){
                return (triggerBy ? Service.getDisplayName(triggerBy) : unknownUser) + " (" + record.triggerType + ")";
            } else {
                return record.triggerType;
            }
        }
        $scope.actionLbl = function(action){
            var tmp = Service.translate("log.actionDesc." + action);
            return tmp ? tmp : action;
        }

        //click cell (tooltipHover)
        $scope.clickHoverCell = function(e){
            var p = tooltipHover.position();
            var x = Math.floor((p.left+0.1) / cellWidth);//+0.1 prevent rounding problem
            var y = Math.floor((p.top+0.1) / rowHeight);

            openTooltip(x, y);
        }
        //click cell(canvas)
        $scope.clickCanvasCell = function(e){
            //click outside canvas
            if (e.target != canvas.get(0))
                return;
            //click on the space between row
            var tmp = e.offsetY % rowHeight;
            if (tmp > cellHeight)
                return;

            var x = Math.floor(e.offsetX / cellWidth);
            var y = Math.floor(e.offsetY / rowHeight);

            openTooltip(x, y);
        }

        function openTooltip(x, y){
            $scope.cellPopup.close();

            //click on future time
            if ($scope.cutoffCellX != null && x >= $scope.cutoffCellX)
                return;

            $timeout(function(){
                tooltipDummy.css({left: x*cellWidth, top: y*rowHeight});
                $scope.cellPopup.open();
                $scope.showHover = false;
            }, animationDuration + 50);
            prepareTooltipData(x, y);
        }
        function prepareTooltipData(cellX, cellY){
            var data = $scope.selectedData[cellY];
            $scope.tooltipData = {
                title: data.name
            }

            var time1 = Service.addMinute($scope.logStartTime, 15 * cellX);
            var time2 = Service.addMinute($scope.logStartTime, 15 * (cellX+1));
            $scope.tooltipData.time = Service.timeFmt(time1) + "-" + Service.timeFmt(time2);

            //get action records
            var records = [];
            var prevAction = data.records[0].action;
            for (var i=1; i<data.records.length; i++){
                var r = data.records[i];
                if (r.timestamp >= time2){
                    break;
                } else if (r.timestamp < time1) {
                    prevAction = r.action;
                } else {
                    records.push(r);
                }
            }
            $scope.tooltipData.records = records.sort(function(a, b){
                return a.timestamp - b.timestamp;
            });

            //calculate ON duration
            var mins = 0;
            var prevOnAt = isActionOn(prevAction) ? time1 : null;
            for (var i=0; i<records.length; i++){
                var r = records[i];
                if (prevOnAt){
                    if (!isActionOn(r.action)){
                        mins += Service.getMinuteDiff(r.timestamp, prevOnAt);
                        prevOnAt = null;
                    }
                } else {
                    if (isActionOn(r.action))
                        prevOnAt = r.timestamp;
                }
            }
            if (prevOnAt)
                mins += Service.getMinuteDiff(time2, prevOnAt);
            $scope.tooltipData.onDuration = Service.numFmt(mins);
        }

        $scope.moveOnCell = function(e){
            //move on tooltipHover
            if (e.target == tooltipHoverElm)
                return;

            //move on tooltipDummy
            if (e.target == tooltipDummyElm){
                $scope.showHover = false;
                return;
            } else {
                $scope.showHover = true;
            }

            //move on the space between row
            var tmp = e.offsetY % (cellHeight+cellPad);
            if (tmp > cellHeight)
                return;

            //hide for future time
            var x = Math.floor(e.offsetX / cellWidth);
            $scope.isFuture = $scope.cutoffCellX != null && x >= $scope.cutoffCellX;

            //set position
            tooltipHover.css({left: Math.floor(e.offsetX / cellWidth)*cellWidth, top: e.offsetY-tmp});
        }
        $scope.moveOutCell = function(e){
            $scope.showHover = false;
        }

        //handle reize window
        $scope.resizeHeatmapHandler = function(){
            $timeout.cancel($scope.resizeHeatmapTimer);
            if (canvas.is(":visible")){
                if (canvasCol.innerWidth() != maxWidth){
                    $scope.resizeHeatmapTimer = $timeout($scope.redrawHeatmap, 300);
                } else {
                    $scope.cellPopup.close();
                }
            }
        }
    /* grid */

    /* chart */
        $scope.times = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm']
        $scope.scrollBarWidth = KEY.scrollBarWidth + 'px';
        $scope.lineChartOpt = {
            seriesDefaults: {
                type: "line",
                style: "step",
                stack: false,
                opacity: 1,
                line: {
                    width: 2
                },
                markers: {
                    size: 4,
                    visible: false,
                },
                overlay: {
                    gradient: "none"
                }
            },
            series: [],
            legend: {
                visible: false
            },
            plotArea: {
                margin: 0,
                padding: 0
            },
            chartArea: {
                margin: 0,
                opacity: 0 
            },
            dataSource: {},
            categoryAxis: {
                visible: false,
                majorGridLines: {
                    visible: false
                },
                field: "cellX"
            },
            valueAxis: {
                visible: false,
                majorGridLines: {
                    visible: false
                }
            },
            tooltip: {
                visible: true,
                shared: true,
                sharedTemplate: $("#logChartTooltip").html(),
            }
        }
        function createSeries(isWarmer, data){
            return {
                field: isWarmer ? "warmer" : "cooler",
                color: isWarmer ? warmerColor : coolerColor,
                timeFmt: chartTooltipTimeFmt,
                actionLbl: $scope.actionLbl,
                actionClass: actionClass,
                countLbl: countLbl,
                noRequestLbl: Service.translate("log.noRequest"),
                highlight: {
                    visual: function(e) {
                        var circleGeometry = new kendo.geometry.Circle(e.rect.center(), 3);
                        var circle = new kendo.drawing.Circle(circleGeometry, {
                            stroke: {
                                color: isWarmer ? warmerColor : coolerColor,
                                width: 2,
                            },
                            fill: {
                                color: "white",
                            }
                        });
                        return circle;
                    },
                },
                data: data
            }
        }
        function chartTooltipTimeFmt(cellXOrUnixTimestamp, isCellX){
            if (isCellX){
                return Service.timeFmt(Service.addMinute($scope.logStartTime, cellXOrUnixTimestamp * 15));
            } else {
                return Service.timeFmt(cellXOrUnixTimestamp);
            }
        }
        function actionClass(action){
            if (action === APIKEY.action.warmer){
                return " redColor";
            }
            return ' lightColor';
        }
        function countLbl(isWarmer, records){
            if (!records)
                return '';
            return Service.translate(isWarmer ? "log.warmerRequest" : "log.coolerRequest") + records.filter(function(r){
                return isWarmer && r.action === APIKEY.action.warmer || !isWarmer && r.action === APIKEY.action.cooler;
            }).length;
        }

        $scope.redrawChart = function(showAnimation){
            if (!$scope.lineChart)
                return;

            var data = [];
            var maxCount = 1;
            var cutoffColNum = $scope.cutoffCellX == null ? colNum : $scope.cutoffCellX;
            for (var i=0; i<cutoffColNum; i++){
                data.push({
                    cooler: -0.5,
                    warmer: 0.5,
                    cellX: i,
                });
            };
            for (var i=cutoffColNum; i<colNum; i++){
                data.push({ cellX: i });
            };
            $scope.selectedData.forEach(function(node){
              if (!node.loading){
                for (var i=1; i<node.records.length; i++){
                    var r = node.records[i];
                    if (r.action === APIKEY.action.cooler || r.action === APIKEY.action.warmer){
                        var cellXData = data[getCellXByTime(r.timestamp)];
                        if (cellXData && cellXData.cellX < cutoffColNum){
                            var d = {
                                timestamp: r.timestamp,
                                action: r.action,
                                name: node.name
                            }
                            if (!cellXData.records){
                                cellXData.records = [d];
                            } else {
                                cellXData.records.push(d);
                            }

                            if (r.action === APIKEY.action.cooler) {
                                cellXData.cooler--;
                                maxCount = Math.max(maxCount, Math.abs(cellXData.cooler));
                            } else {
                                cellXData.warmer++;
                                maxCount = Math.max(maxCount, cellXData.warmer);
                            }
                        }
                    }
                }
                for (var i=0; i<data.length; i++){
                    if (data[i].records){
                        data[i].records.sort(function(a, b){
                            return a.timestamp > b.timestamp ? 1 : -1;
                        });
                    }
                }
              }
            });

            $scope.lineChart.setOptions({
                transitions: showAnimation === true,
                series: [createSeries(true, data), createSeries(false, data)],
                dataSource: data,
                valueAxis: {
                    max: maxCount*1.1,
                    min: -maxCount*1.1
                },
            });
            $scope.lineChart.refresh();
        }

        $scope.resizeChartHandler = function(){
            $timeout.cancel($scope.resizeChartTimer);
            $scope.resizeChartTimer = $timeout($scope.redrawChart, 300);
        }
    /* chart */

    /* handle resize */
        $scope.$on('toggleSideMenu', function(){
            $timeout(function(){
                $scope.resizeHeatmapHandler();
                $scope.resizeChartHandler();
            }, 100);
        });
        angular.element($window).on('resize', $scope.resizeHeatmapHandler);
        angular.element($window).on('resize', $scope.resizeChartHandler);
    /* handle resize */

        $scope.$on('$destroy', function() {
            console.log("logController destroy");
            caller.cancel();
            angular.element($window).off('resize', $scope.resizeHeatmapHandler);
            angular.element($window).off('resize', $scope.resizeChartHandler);
        });

    }
})();
